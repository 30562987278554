.purchase-history-table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;

    tr {
        border-bottom: 1px solid #ffffff;
        td {
            text-align: center;
            padding: 0.5rem;
        }
    }
    tr:nth-child(2n) {
        background-color: #3d3d3d;
    }
}
